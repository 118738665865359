body {
  margin: 0;
  line-height: normal;
  background: var(--color-floralwhite-100) !important;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@font-face {
  font-family: 'Aileronnormal';
  src: url('./fonts/Aileron-Regular.eot');
  src: url('./fonts/Aileron-Regular.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Aileron-Regular.woff') format('woff'),
    url('./fonts/Aileron-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aileronbold';
  src: url('./fonts/Aileron-Bold.eot');
  src: url('./fonts/Aileron-Bold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Aileron-Bold.woff') format('woff'),
    url('./fonts/Aileron-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Aileronsemibold';
  src: url('./fonts/Aileron-SemiBold.eot');
  src: url('./fonts/Aileron-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('./fonts/Aileron-SemiBold.woff') format('woff'),
    url('./fonts/Aileron-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}



:root {
  /* fonts */
  --font-aileron: Aileron;
  --font-aileron-bold: Aileronbold;
  --font-aileron-normal: Aileronnormal;
  --font-aileron-semibold: Aileronsemibold;
  --font-inter: Inter;

  /* font sizes */
  --font-size-xs: 12px;
  --font-size-5xl: 24px;
  --font-size-lgi: 19px;
  --font-size-sm: 14px;
  --font-size-xl: 20px;
  --font-size-base: 16px;
  --font-size-29xl: 48px;
  --font-size-19xl: 38px;
  --font-size-10xl: 29px;
  --font-size-45xl: 64px;
  --font-size-32xl: 51px;
  --font-size-17xl: 36px;
  --font-size-3xl: 22px;

  /* Colors */
  --color-floralwhite-100: #fffbf1;
  --color-floralwhite-200: #fff9ee;
  --color-gray-100: #181818;
  --color-gray-200: rgba(24, 24, 24, 0.2);
  --color-gray-300: rgba(24, 24, 24, 0.05);
  --color-darkslategray-100: #4a4a4a;
  --color-darkslategray-200: #3f322a;
  --color-darkslategray-300: #004e4e;
  --color-darkslategray-400: #004646;
  --color-darkslategray-500: rgba(74, 74, 74, 0.09);
  --color-gainsboro-100: #e6e6e6;
  --color-gainsboro-200: #e6e0d9;
  --color-white: #fff;
  --color-royalblue: #3173fa;
  --color-lightblue-100: #bfebfe;
  --color-linen: #efe5d9;
  --color-lightgray-100: #d6ccbf;
  --color-lightgray-200: #d4c8ba;
  --color-black: #000;
  --color-darkgoldenrod: #c67700;
  --color-ghostwhite: #fbf9ff;
  --color-gold: #feed5a;
  --color-mediumslateblue: #a56eff;
  --color-homemenu: #181818;
  --color-white: #ffffff;
  --color-yellow: #febe10;
  --color-blue: #264d9b;

  /* Gaps */
  --gap-45xl: 64px;
  --gap-13xl: 32px;
  --gap-base: 16px;
  --gap-5xl: 24px;
  --gap-3xl: 22px;
  --gap-xl: 20px;
  --gap-smi-6: 12.6px;
  --gap-21xl: 40px;
  --gap-29xl: 48px;
  --gap-7xs: 6px;
  --gap-17xl: 36px;
  --gap-xs: 12px;
  --gap-7xl: 26px;
  --gap-41xl: 60px;
  --gap-11xl: 30px;
  --gap-4xl: 23px;
  --gap-lg: 18px;
  --gap-528xl: 547px;
  --gap-118xl: 137px;
  --gap-254xl: 273px;
  --gap-61xl: 80px;
  --gap-3xs: 10px;
  --gap-16xl: 35px;
  --gap-555xl: 574px;
  --gap-124xl: 143px;
  --gap-268xl: 287px;
  --gap-9xl: 28px;
  --gap-2xl: 21px;
  --gap-5xs: 8px;

  /* Paddings */
  --padding-base: 16px;
  --padding-17xl: 36px;
  --padding-7xs: 6px;
  --padding-5xl: 24px;
  --padding-xl: 20px;
  --padding-3xs: 10px;
  --padding-mid: 17px;
  --padding-5xs: 8px;
  --padding-lg: 18px;
  --padding-9xs: 4px;
  --padding-15xl: 34px;
  --padding-2xs: 11px;
  --padding-lgi: 19px;
  --padding-8xs: 5px;
  --padding-31xl: 50px;
  --padding-2xl: 21px;
  --padding-13xl: 32px;
  --padding-21xl: 40px;
  --padding-7xl: 26px;
  --padding-58xl: 77px;
  --padding-11xs: 2px;
  --padding-4xs: 9px;
  --padding-3xl: 22px;
  --padding-12xs: 1px;
  --padding-smi: 13px;
  --padding-18xl: 37px;
  --padding-41xl: 60px;
  --padding-6xl: 25px;
  --padding-20xl: 39px;
  --padding-xs: 12px;
  --padding-base-4: 16.4px;
  --padding-4xl: 23px;
  --padding-9xl: 28px;
  --padding-10xl: 29px;
  --padding-14xl: 33px;
  --padding-sm: 14px;
  --padding-6xs: 7px;
  --padding-300xl: 319px;
  --padding-188xl: 207px;
  --padding-330xl: 349px;
  --padding-208xl: 227px;
  --padding-16xl: 35px;
  --padding-mini: 15px;
  --padding-2xs-6: 10.6px;
  --padding-78xl: 97px;
  --padding-38xl: 57px;
  --padding-52xl: 71px;
  --padding-27xl: 46px;

  /* Border radiuses */
  --br-5xs: 8px;
  --br-21xl: 40px;

  --space-card: calc(20px +(100vw - 1200px) /(2400 - 1200)*(24 - 20));
}

.contactpopup {
  position: fixed;
  background: var(--color-blue);
  bottom: -100%;
  left: 0;
  right: 0;
  min-height: 464px;
  box-sizing: border-box;
  padding: 36px;
  transition: all .5s ease-in-out;
  width: 100%;
  max-width: calc(100% - 72px);
  margin: 0 auto;
  border-radius: 12px;
  z-index: 9;
}

.contactpopup.halfshow {
  bottom: -260px;
}

.contactpopup.fullshow {
  bottom: 36px;
}

.contactpopup .firstrow {
  display: flex;
  font-family: var(--font-aileron-bold);
  font-size: 16px;
  color: #fff;
  justify-content: space-between;
  margin-bottom: 30px;
}

.contactpopup .firstrow p {
  padding: 0;
  margin: 0;
  max-width: 708px;
  line-height: 22px;
  text-transform: uppercase;
}

.contactpopup .firstrow a {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  background: url(../public/linkedin.svg) no-repeat;
  background-size: 100%;
  text-decoration: none;
}

.contactpopup .emailcolumn {
  padding-top: 76px;
}

.contactpopup .emailcolumn a {
  font-family: var(--font-aileron-bold);
  font-size: 90px;
  color: #fff;
  text-decoration: none;
}

.contactpopup .keep {
  padding-top: 76px;
  font-family: var(--font-aileron-bold);
  font-size: 16px;
  color: #fff;
}

.contactpopup .emailboxparent {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 726px;
  padding-top: 18px;
  flex-flow: column;
}

.contactpopup .emailboxparent input[type="text"],
.contactpopup .emailboxparent input[type="email"],
.contactpopup .emailboxparent input[type="password"] {
  min-width: 350px;
  max-width: 350px;
  height: 40px;
  display: flex;
  align-items: center;
  padding-left: 4px;
  font-family: var(--font-aileron-normal);
  color: #fff;
  background: transparent;
  border: none;
  box-shadow: none;
  border-bottom: 1px solid #fff;
  outline: none;
  margin-bottom: 40px;
  font-size: 22px;
}

.contactpopup .emailboxparent input[type="email"]::placeholder,
.contactpopup .emailboxparent input[type="password"]::placeholder {
  font-size: 22px;
  color: #fff;
}

.contactpopup .emailboxparent a {
  display: flex;
  font-family: var(--font-aileron-normal);
  color: #fff;
  margin-bottom: 30px;
}

.contactpopup .emailboxparent input[type="submit"] {
  width: 116px;
  height: 40px;
  border: 1px solid #F7F7F7;
  color: #F7F7F7;
  border-radius: 40px;
  background: transparent;
  outline: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font-aileron-semibold);
  font-size: 16px;
  color: #FFFFFF;
}

footer .privacybox {
  padding-top: 0;
  display: flex;
  justify-content: flex-end;
}

footer .privacybox a {
  margin-right: 24px;
  color: #F7F7F7;
  font-family: var(--font-aileron-semibold);
  font-size: 14px;
  text-decoration: none;
}

footer .privacybox a:last-child {
  margin-right: 0;
}

.mobilehomeburger {
  display: none;
}

.showonwb {
  display: block;
}

.showonmobile {
  display: none;
}

.fixedheader .rectangle-parent2,
.fixedheader .frame-section {
  margin-top: 200px;
}


body.loaded {
  opacity: 1;
}

.root-header.showsubmenu,
.frame-wrapper3.showsubmenu {
  position: fixed;
  right: 0;
  top: 98px;
  margin-top: 0;
  left: 0;
  z-index: 9;
}

.root-header.showsubmenu .rectangle-parent2,
.frame-wrapper3.showsubmenu .rectangle-group {
  margin-top: 0;
  z-index: 99;

}

.frame-container {
  width: auto;
}

.about-parent {
  width: auto;
  gap: 0;
  justify-content: space-between;
  align-items: center;
}

.about-parent>div {
  width: auto;
  min-width: auto;
}

.logoimg {
  width: 130px;
  height: auto;
}

.contactweb {
  display: block !important;
}

.contactmobile {
  display: none !important;
}

.about-parent div a {
  color: var(--color-blue);
}

.premise b {
  text-transform: uppercase;
}

.contactboxparent {
  display: flex;
}

.contactboxparent>div:first-child {
  max-width: 400px;
}

.contactboxparent>div.imagebox {
  max-width: 300px;
  position: absolute;
  bottom: 20px;
  right: 100px;
}

.contactboxparent>div.imagebox img {
  max-width: 100%;
}

.features_sec {
  padding-top: 50px;
  position: relative;
  padding-bottom: 0;
  width: 100%;
}

#upcoming_f {
  background: #efefef;
}

.features_sec h3.title {
  padding-bottom: 50px;
  font-family: 'Quicksand';
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  color: var(--color-blue);
  font-family: var(--font-aileron-bold);
}

#upcoming_f h3.title {
  padding-bottom: 20px;
}

.features_sec h3.title+p {
  font-size: 20px;
  font-weight: 500;
  color: var(--color-blue);
  padding-bottom: 40px;
  text-align: center;
  font-family: 'Quicksand';
}

.features_sec .featurebox {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-bottom: 0;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.features_sec .featurebox>div {
  padding-bottom: 50px;
  width: 25%;
}

.features_sec .featurebox>div p {
  font-family: var(--font-aileron-normal);
  font-size: 22px;
  color: var(--color-blue);
  text-align: center;
  padding-bottom: 0;
  margin: 0;
}

.features_sec .featurebox>div img {
  max-width: 75%;
  display: flex;
  margin: 0 auto;
  margin-top: 20px;
}

#upcoming_f .featurebox>div p {
  color: #444;
}

.features_sec .view_more {
  width: 200px;
  height: 48px;
  border-radius: 12px;
  border: solid 2px var(--color-blue);
  background-color: #efefef;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  cursor: pointer;
  font-family: 'Quicksand';
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: var(--color-blue);
}

footer {
  padding: 36px;
  background: var(--color-yellow);
  color: #fff;
  display: flex;
  justify-content: space-between;
}

footer p {
  font-size: 16px;
}

.about-parent a.profilepicboxparent {
  display: flex;
  align-items: center;
  padding-right: 0;
}

.profilepicbox {
  width: 40px;
  height: 40px;
  background: #ccc;
  border-radius: 50%;
  margin-left: 10px;
}

.boxparent {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 36px;
  margin-top: 88px;
  position: relative;
}

.topprograssstart {
  padding: 5px 10px 15px 10px;
  /* box-shadow: 0 0 0 0 rgba(90, 113, 208, 0.11), 0 4px 16px 0 rgba(167, 175, 183, 0.33); */
  border-radius: 0.35rem;
  background: #fff;
  margin-bottom: 10px;
  width: 100%;
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
  border: 1px solid #E1E1E1;
}

.topprograssstart:hover {
  box-shadow: 0 0 0 0 rgba(90, 113, 208, 0.11), 0 4px 16px 0 rgba(167, 175, 183, 0.33);
  transform: translateY(-4px) scale(1.02);
}

.pro-border {
  border-bottom: 1px solid #f4f4f4;
  padding-bottom: 15px;
  margin-bottom: 20px;
  font-family: var(--font-aileron-bold);
  font-size: 20px;
}

.progress-minibar {
  height: 5px !important;
  margin-bottom: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, .1);
}

.progress,
.progress>.progress-bar,
.progress .progress-bar,
.progress>.progress-bar .progress-bar {
  border-radius: 1px;
}

.sidebar {
  width: 220px;
  height: calc(100vh - 88px);
  background: #fff;
  border-right: 1px solid #E1E1E1;
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
  padding-left: 36px;
  position: fixed;
  left: 0;

}

.sidebar.collapsed {
  width: 70px;
  overflow: hidden;
  padding-left: 20px;
  padding-right: 20px;
}

.parentarea {
  width: calc(100% - 256px);
  padding-top: 36px;
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
  margin-left: 256px;
}

.parentarea.collapsed {
  width: calc(100% - 108px);
  margin-left: 108px;
}

.headerpart {
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 9;
}

.headerpart>div {
  background: transparent;
  border-bottom: 1px solid #E1E1E1;
}

.sidebar>div .about-parent {
  display: flex;
  flex-flow: column;
  text-align: left;
  overflow: hidden;
}

.sidebar>div .about-parent>div a {
  display: flex;
  padding: 0;
  text-align: left;
  margin: 20px 0 10px 0;
  align-items: center;
}

.sidebar>div .about-parent>div a svg {
  margin-right: 10px;
  min-width: 20px;
  height: 20px;
}

.sidebar>div .about-parent>div {
  width: 100%;
}

.sidebar .menubarbar {
  margin-top: 36px;
  cursor: pointer;
  margin-bottom: 10px;

}

.sidebar .menubarbar svg {
  width: 26px;
  height: 26px;
}

.box {
  position: relative;
  border-radius: 0.35rem;
  background: #ffffff;
  margin-bottom: 10px;
  width: 100%;
  /* box-shadow: 0 1px 1px rgba(0,0,0,0.1); */
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
  border: 1px solid #E1E1E1;
}

.box:hover {
  box-shadow: 0 0 0 0 rgba(90, 113, 208, 0.11), 0 4px 16px 0 rgba(167, 175, 183, 0.33);
  transform: translateY(-4px) scale(1.02);
}

.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.box-header a {
  color: var(--color-blue);
  font-family: var(--font-aileron-semibold);
  text-decoration: none;
}

.topprograssstart .bg-success {
  background-color: var(--color-blue) !important;
}

.box-header>.fa,
.box-header>.glyphicon,
.box-header>.ion,
.box-header .box-title {
  display: inline-block;
  font-size: 20px;
  margin: 0;
  line-height: 1;
  font-family: var(--font-aileron-bold);
}

.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px;
}

.direct-chat-messages {
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  -o-transform: translate(0, 0);
  transform: translate(0, 0);
  padding: 10px;
  height: 234px;
  overflow: auto;
}

.direct-chat-messages,
.direct-chat-contacts {
  -webkit-transition: -webkit-transform .5s ease-in-out;
  -moz-transition: -moz-transform .5s ease-in-out;
  -o-transition: -o-transform .5s ease-in-out;
  transition: transform .5s ease-in-out;
}

.user-progress {
  padding-left: 0;
  list-style: none;
  padding-right: 0;
}

.msr-3 {
  margin-right: 1rem;
}

.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}

.img-circle {
  border-radius: 50%;
}

.lecture-list {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 5px;
  vertical-align: middle;
  padding: 10px;
  margin-bottom: 10px;
  background: #f5f5f5;
}

.object-fit-cover {
  object-fit: cover;
}

.fit-image-40 {
  min-height: 40px;
}

.media-title {
  font-family: var(--font-aileron-semibold);
  font-size: 16px;
}

.media-title>div {
  font-family: var(--font-aileron-normal);
  font-size: 14px;
}

.label-success {
  background: #febe10;
}

.box .box-body .label {
  padding: 2px 5px;
  color: #fff;
  border-radius: 5px;
  font-family: var(--font-aileron-normal);
  font-size: 12px;
}

.topprograssstart p {
  display: flex;
  justify-content: space-between;
  font-family: var(--font-aileron-semibold);
  text-transform: none !important;

}

.topprograssstart p span {
  font-family: var(--font-aileron-normal);
}

.progress-bar-aqua,
.progress-bar-info {
  background-color: #00c0ef;
}

.topprograssstart p::after {
  display: none;
}

.topprograssstart .progress-group {
  margin-bottom: 10px;
}

.info-box {
  display: block;
  min-height: 60px;
  background: #fff;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  margin-bottom: 10px;
  border-radius: 0.35rem;
}

.info-box {
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
  display: block;
  cursor: pointer;
  border: solid 1px #dde4eb;
  transition: all .3s cubic-bezier(.25, .8, .25, 1);
}

.info-box a {
  color: var(--color-blue);
  text-decoration: none;
  transition: all 0.3s linear;
  width: 100%;
  display: flex;
  padding: 10px;
  align-items: center;
}

.info-box:hover {
  box-shadow: 0 0 0 0 rgba(90, 113, 208, 0.11), 0 4px 16px 0 rgba(167, 175, 183, 0.33);
  transform: translateY(-4px) scale(1.02);
}

.info-box a svg {
  display: block;
  float: left;
  height: 60px;
  width: 60px;
  text-align: center;
  font-size: 32px;
  line-height: 60px;
  background: var(--color-yellow);
  border-radius: 3px;
  color: #fff;
  padding: 8px;
}

.info-box a>div span {
  margin-left: 10px;
  font-family: var(--font-aileron-bold);
  font-size: 16px;
}

.sidebar>div .about-parent>div a.active {
  color: var(--color-yellow);
}

@media screen and (min-width:0) and (max-width:767px) {
  .mobilehomeburger {
    display: flex;
    width: 24px;
    height: 24px;
    background: url(../public/Menu.png) no-repeat;
    background-size: 100%;
    cursor: pointer;
    margin-left: auto;
  }

  .mobilehomeburger.updateshome {
    display: flex;
    width: 24px;
    height: 24px;
    background: url(../public/updateshomemenu.png) no-repeat;
    background-size: 100%;
    cursor: pointer;
    margin-left: auto;
  }

  header a.group-parent {
    display: flex;
    align-items: center;
  }

  header a.group-parent .group-icon {
    height: auto;
    width: 25px;
  }

  header a.group-parent .group-icon+.group-icon1 {
    height: auto;
    width: 164px;
  }

  .about-parent,
  .vector-parent,
  .contactpopup {
    display: none;
  }

  body.open header {
    position: fixed;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    align-items: flex-start;
    padding: 16px !important;
    background: #FFFBF1;
    left: 0;
    box-sizing: border-box;
    gap: 0;
    flex-flow: column;
    justify-content: flex-start;
    background: var(--color-floralwhite-100) !important;
    overflow: auto;
  }

  body.open .about-parent {
    display: flex;
    flex-flow: column;
    width: 100%;
    padding-top: 36px;
  }

  body.open .about-parent>div {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;
  }

  body.open .about-parent>div {
    width: 100%;
    text-align: left;
    margin-bottom: 10px;
  }

  body.open .about-parent>div a {
    padding-left: 0;
    font-size: 24px;
    padding-bottom: 0;
    color: #181818;
  }

  body.open .about-parent>div a.active {
    background: transparent;
  }

  body.open .about-parent+.mobilehomeburger {
    right: 16px;
    top: 36px;
    position: absolute;
    background: url(../public/Menucross.svg) no-repeat;
    background-size: 100%;
  }

  body.open .contactpopup {
    display: flex;
    bottom: 0;
    padding: 0;
    max-width: calc(100% - 32px);
    flex-flow: column;
    background: transparent;
    min-height: auto;
    border-top: 1px solid #181818;
    border-radius: 0;
    padding-top: 20px;
    position: absolute !important;
    top: 326px;
    overflow-x: hidden;
    overflow-y: auto;
    padding-left: 2px;
  }

  body.open {
    overflow: hidden;
  }

  body.open .contactpopup .firstrow p {
    font-size: 12px;
    line-height: 18px;
    color: #181818;
    white-space: normal;
  }

  body.open .contactpopup .emailcolumn {
    padding-top: 26px;
  }

  body.open .contactpopup .emailcolumn a {
    font-size: 24px;
    color: #181818;
  }

  body.open .contactpopup .keep {
    padding-top: 16px;
    font-size: 14px;
    color: #181818;
    line-height: 20px;
    white-space: normal;
  }

  body.open .contactpopup .emailboxparent {
    flex-flow: column;
  }

  body.open .contactpopup .emailboxparent input[type="text"],
  body.open .contactpopup .emailboxparent input[type="email"] {
    min-width: 100%;
    margin-bottom: 16px;
    font-size: 12px;
  }

  body.open .contactpopup .emailboxparent input[type="submit"] {
    border-color: #181818;
    margin-top: 8px;
    color: #181818;
  }

  body.open .contactpopup .privacybox {
    justify-content: flex-start;
    padding-top: 28px;
    padding-bottom: 15px;
    position: relative;
    right: auto;
    bottom: auto;

  }

  body.open .contactpopup .privacybox a {
    font-size: 12px;
    color: #181818;
  }

  body.open .contactpopup .firstrow a {
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    background: url(../public/blacklinkedin.svg) no-repeat;
    background-size: 100%;
  }

  .fixedheader header {
    padding: 16px !important;
  }

  .slick-list {
    padding: 0 20% 0 0 !important;
  }

  .slick-initialized .slick-slide {
    /* min-width: 290px; */
    /* min-height: 320px; */
    margin-right: 20px;
    /* aspect-ratio: 245 / 280; */
    max-width: 245px;
  }

  .building-on-legacy {
    font-size: 28px;
    line-height: 32px;
  }

  .were-an-investment-group-form-wrapper {
    padding: 0 24px;
    font-size: 16px;
  }

  .homepage-ver-11-inner {
    padding-bottom: 0;
  }

  .premise-parent .rectangle-parent {
    height: auto;
    min-height: calc(100% - 40px);
  }

  .premise-parent .rectangle-parent .spring-street-capital {
    font-size: 20px;
    line-height: 28px;
  }

  .root-header {
    /* display: none; */
    margin-top: 0;
    position: absolute !important;
    top: 100px !important;
    left: 0;
    width: 100%;
    right: 0;
    left: 0;
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .rectangle-parent1,
  .rectangle-parent19,
  .rectangle-container {
    height: 370px;
    min-height: 370px;
    padding-bottom: 25px;
  }

  .why-us4,
  .how-we-invest5 {
    height: auto;
    font-size: 24px;
  }

  .were-a-buyer,
  .we-keep-things {
    font-size: 14px;
  }

  .why-us-inner {
    padding-left: 16px;
    padding-right: 0;
  }

  .your-team-customers-and-lega-wrapper {
    padding-left: 0;
    padding-right: 16px;
  }

  .your-team-customers-container {
    height: auto;
  }

  .frame-wrapper14 {
    padding-left: 0;
    padding-right: 0;
  }

  .strategic-commitment-parent>div>div {
    font-size: 12px;
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .strategic-commitment-parent>div:first-child>div:nth-child(3),
  .strategic-commitment-parent>div:first-child>div:nth-child(4) {
    font-size: 16px;
  }

  .strategic-commitment-parent>div:first-child>div:nth-child(2) {
    font-size: 16px;
  }

  .vector-parent6 {
    padding-right: 16px;
  }

  .terms-user-f-i-parent>div h3 {
    height: auto;
  }

  .terms-user-f-i-parent>div h3 {
    font-size: 16px;
    min-width: calc(100% - 105px);
  }

  .terms-user-f-i2 {
    flex-wrap: nowrap;
  }

  .why-us-child {
    padding-left: 16px;
    padding-right: 16px;
  }

  .collective-stats-of {
    font-size: 14px;
  }

  .uncertain-depends {
    row-gap: 40px;
  }

  .uncertain-depends>div {
    row-gap: 16px;
    padding-left: 0;
    border: none;
    border-bottom: 1PX SOLID #181818;
    padding-bottom: 24px;
    min-width: 100%;
  }

  .how-we-invest-inner1 {
    padding: 0 16px;
  }

  .frame-wrapper32 {
    padding: 0;
  }

  .frame-parent70>div {
    border: none;
    padding-bottom: 36px;
    border-bottom: 1px solid #181818;
    padding-top: 24px;
    padding-left: 0;
  }

  .frame-parent70>div:last-child {
    padding-bottom: 72px;
  }

  .frame-child99 {
    display: none;
  }

  .what-a-business-owner-can-expe-wrapper {
    padding-bottom: 10px;
    font-size: 14px;
  }

  .daysparent {
    max-width: 336px;
  }

  .daysparent .daysbox .day {
    min-width: 96px;
    margin-right: 15px;
    font-family: var(--font-aileron-semibold);
  }

  .daysparent .daysbox .circle {
    margin-right: 12px;
  }

  .daysparent .daysbox:first-child:before,
  .daysparent .daysbox:after {
    left: 126px;
  }

  .daysparent .daysbox:last-child:before {
    left: 68px;
  }

  .daysparent .daysbox .lastbox .heading {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .daysparent .daysbox .lastbox .text {
    font-size: 14px;
  }

  .daysparent .daysbox {
    padding-bottom: 80px;
  }

  .daysparent .daysbox:last-child {
    padding-bottom: 220px;
  }

  .daysparent .after {
    left: 126px;
    top: 0;
    max-height: 1295px;
  }

  .rectangle-container {
    padding-left: 16px;
    padding-right: 16px;
  }

  .our-focus2 {
    height: auto;
    font-size: 24px;
  }

  .were-looking-to {
    font-size: 14px;
    line-height: 24px;
    max-width: 270px;
    margin: 0 auto;
    height: auto;
  }

  .showonwb {
    display: none;
  }

  .showonmobile {
    display: block;
  }

  .graph {
    padding-top: 36px;
    padding-left: 9px;
  }

  .graph img {
    max-width: 100%;
  }

  .icon-set {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 31px;
  }

  .our-commitment {
    font-size: 14px;
    height: auto;
  }

  .figma-toolbox-inner {
    padding: 0;
  }

  .frame-parent13>div,
  .frame-parent13>div>div {
    position: relative;
    left: 0;
    min-width: 100%;
    gap: 0;
    padding-left: 0;
    border: none;
  }

  .frame-parent13>div>div>div,
  .figma-toolbox {
    position: relative;
    gap: 0;
  }

  .our-commitment-wrapper {
    padding-top: 12px;
    padding-bottom: 47px;
  }

  .frame-parent13>div b {
    font-size: 20px;
    height: auto;
    padding: 24px 0 16px 0;
  }

  .frame-parent14 {
    display: flex;
    align-items: flex-start;
    font-size: 16px;
    height: auto;
    padding-bottom: 20px;
  }

  .frame-parent14:last-child {
    padding-bottom: 24px;
    border-bottom: 1px solid #181818;
  }

  .frame-parent14>div:first-child {
    padding: 0;
    margin-top: 5px;
  }

  .b2b-services,
  .software-serving-energy,
  .minimal-business-cycle,
  .owner-ready-to,
  .diverse-loyal-customer,
  .us-based,
  .predictable-recurring-or,
  .software-2-5m-arr,
  .gross-margins {
    height: auto;
    font-size: 16px;
    padding-left: 10px;
    min-height: auto;
  }

  .fi-10822524-parent,
  .fi-4822929-parent {
    margin-top: 42px;
  }

  .frame-parent19,
  .frame-parent20 {
    display: flex;
    align-items: flex-start;
    padding-bottom: 20px;

  }

  .ellipse-wrapper1,
  .ellipse-wrapper2 {
    padding-top: 5px;
    padding-bottom: 0;
  }

  .predictable-recurring-or,
  .diverse-and-loyal {
    height: auto;
  }

  .frame-parent17 {
    width: 100%;
  }

  .fi-2976445-parent {
    margin-top: 18px;
    padding-bottom: 0;
  }

  .fi-2976445-parent .frame-parent14:last-child {
    /* border-bottom: none; */
  }

  .we-recognize-that-each-busines-wrapper {
    padding: 10px 0 0 0;
  }

  .we-recognize-that-container p {
    font-size: 16px;
  }

  .frame-wrapper3 {
    display: none;
  }

  .teams-page {
    padding-left: 16px;
    padding-right: 16px;

  }

  .teams-page .rectangle-container {
    display: flex;
    flex-flow: column;
    padding: 0;
    min-height: auto;
    height: auto;
  }

  .teams-page .rectangle-container img {
    width: 100%;
    height: auto;
    min-height: auto;
    ;
  }

  .group-div {
    width: 100%;
    padding: 24px;
    gap: 0;
  }

  .sanket-bhasin {
    font-size: 24px;
  }

  .sanket-bhasin {
    font-size: 16px;
  }

  .sanket-bhasin-brings-almost-tw-parent {
    gap: 0;
    padding-top: 40px;
  }

  .sanket-bhasin-brings {
    font-size: 20px;
    height: auto;
    white-space: normal;
    line-height: normal;
  }

  .growing-up-in-container {
    padding-top: 24px;
    line-height: normal;
    height: auto;
  }

  .growing-up-in-container p {
    white-space: normal;
    font-size: 16px;
    line-height: 22px;
  }

  .frame-section {
    padding-bottom: 30px;
  }

  .savannah-nguyen-c-e-o,
  .our-team-members,
  .savannah-nguyen-c-e-o1,
  .investor-narrative {
    padding: 0;
  }

  .our-investors,
  .our-advisors {
    font-size: 32PX;
    line-height: normal;
    text-align: left;
    height: auto;
  }

  .a-story-of,
  .a-story-of1 {
    font-size: 16px;
    height: auto;
    white-space: normal;
    text-align: left;
    line-height: normal;
  }

  .frame-parent5,
  .frame-parent18 {
    flex-flow: column;
    width: 100%;
  }

  .frame-parent5>div,
  .frame-parent18>div {
    width: 100%;
    height: auto;
  }

  .frame-parent5>div img,
  .frame-parent18>div img {
    transition: all .3s ease-in-out;
    border-radius: 8px;
    cursor: pointer;
    aspect-ratio: 344 / 382;
    object-fit: cover;
    height: auto;
  }

  .teams-page-child {
    padding-bottom: 20px;
  }

  .updates4 .frame-main {
    padding-left: 0;
    padding-right: 0;
    gap: 0;
  }

  h2.headertitle {
    margin: 60px 0;
    margin-top: 110px;
  }

  .group-section {
    padding: 0;
    gap: 0;
  }

  .group-section>div {
    width: 100%;
    min-width: 100%;
    margin-bottom: 20px;
  }

  .foundation-story-wrapper .the-spring-street {
    font-family: var(--font-aileron-bold);
    font-size: 24px;
    height: auto;
  }

  .foundation-story-wrapper .a-founding-story {
    font-family: var(--font-aileron-semibold);
    font-size: 14px;
    height: auto;
    line-height: normal;
  }

  .team-communicate-this {
    padding-left: 16px;
    padding-right: 16px;
  }

  .from-a-young-age-sanket-bhasi-wrapper,
  .sankets-experiences-growing-u-wrapper,
  .while-attending-business-schoo-wrapper {
    padding-left: 0;
    padding-right: 0;
  }

  .frame-wrapper7,
  .frame-wrapper8,
  .frame-wrapper9 {
    padding: 0;
    height: 244px;
  }

  .deeply-rooted-in {
    font-size: 14px;
  }

  .about-premise .rectangle-parent5 {
    padding-left: 16px;
    padding-right: 16px;
    gap: 0;
    min-height: 370px;
  }

  .the-premise {
    font-size: 24px;
    height: auto;
  }

  .small-business-entrepreneurs {
    font-size: 14px;
    line-height: 18px;
  }

  .about-premise {
    gap: 0;
  }

  .about-premise-inner {
    padding-bottom: 0;
  }

  .entrepreneurs-are-the-unsung-h-wrapper {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 36px;
    padding-bottom: 60px;
  }

  .entrepreneurs-are-the {
    font-size: 20px;
    font-family: var(--font-aileron-semibold);
  }

  .frame-parent27 {
    width: 100%;
  }

  .frame-parent27>div {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    padding: 0;
    border: none;
    border-bottom: 1px solid #181818;
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .frame-parent27>div:last-child {
    padding-bottom: 48px;
  }

  .accountable-for,
  .of-net-new {
    display: inline;
  }

  .about-premise-child {
    padding-bottom: 0;
  }

  .header-unsung-heroes-of-americ-wrapper,
  .spring-street-capital-label-wrapper {
    padding: 10px 16px 48px 16px;
  }

  .about-premise-inner1 {
    display: none;
  }

  .the-unsung-heroes,
  .the-problem,
  .spring-street-capital2,
  .small-businesses-create,
  .as-owners-exit,
  .business-closures-are {
    font-size: 14px;
  }

  .spring-street-capital-label {
    gap: 0;
  }

  .the-problem-parent,
  .spring-street-capital2 {
    padding-top: 10px;
  }

  .small-business-entrepreneurs1 {
    padding-left: 0;
  }

  .spring-street-capital1 {
    gap: 0;
    height: auto;
  }

  .teamsliderparent {
    padding: 10px 16px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
    background: #fffbf1;
  }

  .teamsliderparent .slick-initialized .slick-slide {
    min-height: auto;
  }

  button.slick-prev.slick-arrow {
    left: -16px;
  }

  button.slick-next.slick-arrow {
    right: -16px;
  }

  .premise-parent.slick-initialized.slick-slider button {
    position: absolute;
    right: 0;
    width: 16px;
    height: 12px;
    font-size: 0;
    border: none;
    outline: none;
    left: auto;
    background-color: var(--color-floralwhite-100) !important;
  }

  .premise-parent.slick-initialized.slick-slider button.vector-group {
    position: relative;
    border: 1px solid #FFFBF1;
    border-radius: 3px;
    width: 136px;
    height: 32px;
    display: block;
    border-radius: 40px;
    background: linear-gradient(to right, white 50%, transparent 50%);
    background-size: 200% 100%;
    background-position: right bottom;
    transition: all .5s ease-out;
    font-size: 12px !important;
    background-color: transparent !important;
  }

  .premise-parent.slick-initialized.slick-slider button.slick-next.slick-arrow {
    background: url(../public/homenext.svg) no-repeat;
    background-size: 16px 12px;
    background-position: center 0;
    padding-left: 53px;
    z-index: 8;
    padding-bottom: 27px;
    right: -13px;
    top: 15px;

  }

  .premise-parent.slick-initialized.slick-slider button.slick-prev.slick-arrow {
    background: url(../public/homeback.svg) no-repeat;
    background-size: 16px 12px;
    background-position: center 0;
    right: 27px;
    padding-top: 14px;
    z-index: 8;
    padding-left: 15px;
    padding-right: 22px;
    top: 10px;
  }

  .fixedheader .rectangle-parent2,
  .fixedheader .frame-section {
    margin-top: 80px;
  }

  .building-on-legacy-for-a-bette-parent {
    margin-top: 0;
  }

  .were-an-investment {
    font-size: 16px;
    line-height: 25px;
  }

  .premise .imgparent {
    position: relative !important;
    overflow: hidden;
  }

  .premise .imgparent img,
  .premise .imgparent video {
    width: 100%;
    height: 100%;
    position: absolute !important;
    object-fit: cover;
    transform: scale(1.1);
    transition: all .3s ease-in-out;
  }

  .premise .imgparent:hover img,
  .premise .rectangle-parent:hover {
    top: 0;
    transform: scale(1);
  }

  .premise:hover .vector-group a {
    color: #fff;
  }

  .about-our-story {
    gap: 36px 60px;
  }

  .deeply-rooted-in-belief-wrapper b,
  .commitment-to-entrepreneurship,
  .betting-on-america {
    font-size: 14px;
  }

  .vector-parent4 p {
    font-size: 16px;
  }

  .deeply-rooted-in-belief-wrapper {
    padding-bottom: 0;
  }

  .diverse-and-loyal {
    padding-left: 10px;
  }

  .days-to-complete-transaction {
    gap: 0;
  }

  .how-we-invest-frame {
    margin-bottom: 8px;
  }

  .we-keep-things {
    max-width: 294px;
    font-size: 14px;
    line-height: normal;
    margin-left: auto;
    margin-right: auto;
  }

  .frame-parent30 {
    gap: 0;
  }

  .frame-parent30 .why-us-frame {
    margin-bottom: 12px;
  }

  .were-a-buyer {
    line-height: normal;
    height: auto;
  }

  .frame-parent5>div:hover img,
  .frame-parent18>div:hover img {
    transform: scale(.98);
  }

  .teamsliderparent button.slick-prev.slick-arrow {
    left: auto;
    background: url(../public/homeback.png) no-repeat !important;
    width: 16px;
    height: 12px;
    top: -15px;
    right: 32px;
  }

  .teamsliderparent button.slick-next.slick-arrow {
    background: url(../public/homenext.png) no-repeat !important;
    width: 16px;
    height: 12px;
    right: 0;
    top: -15px;
  }

  .teamsliderparent {
    padding-top: 35px;
    z-index: 8;
    max-height: 139px;
  }

  .teams-page .rectangle-container {
    padding-bottom: 80px;
  }

  .contactweb {
    display: none !important;
  }

  body.open header .contactmobile {
    display: flex !important;
    position: relative !important;
    top: 50px !important;
    height: auto;
    margin-left: 0;
    margin-right: 0;
    max-width: 100%;
    text-align: left;
  }

  .updates4+div#contactpopup {
    display: flex !important;
    flex-flow: column;
    margin-left: 16px;
    margin-right: 16px;
    width: 100%;
    max-width: calc(100% - 32px);
    box-sizing: border-box;
    position: relative !important;
    top: auto;
  }

  .updates4+div#contactpopup .firstrow p {
    font-size: 12px;
    line-height: 18px;
    color: #fff;
  }

  .updates4+div#contactpopup .emailcolumn {
    padding-top: 26px;
  }

  .updates4+div#contactpopup .emailcolumn a {
    font-size: 24px;
    color: #fff;
  }

  .updates4+div#contactpopup .keep {
    padding-top: 16px;
    font-size: 14px;
    color: #fff;
    line-height: 20px;
  }

  .updates4+div#contactpopup .emailboxparent {
    flex-flow: column;
  }

  .updates4+div#contactpopup .emailboxparent input[type="text"],
  .updates4+div#contactpopup .emailboxparent input[type="email"] {
    min-width: 100%;
    margin-bottom: 16px;
    font-size: 12px;
  }

  .updates4+div#contactpopup .emailboxparent input[type="submit"] {
    border-color: #fff;
    margin-top: 8px;
    color: #fff;
  }

  .updates4+div#contactpopup .privacybox {
    justify-content: flex-start;
    padding-top: 28px;
    padding-bottom: 15px;
    position: relative;
    right: auto;
    bottom: auto;

  }

  .updates4+div#contactpopup .privacybox a {
    font-size: 12px;
    color: #fff;
  }

  .updates4+div#contactpopup .firstrow a {
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    background: url(../public/blacklinkedin.svg) no-repeat;
    background-size: 100%;
  }

  .accordion-header p {
    font-size: 20px;
    max-width: 85%;
  }

  .accordion-content p {
    font-size: 16px;
    line-height: normal;
  }

  .teams-page .rectangle-container.teampagemobile {
    padding-bottom: 0;
  }

  .accordion-header span {
    width: 18px;
    height: 18px;
  }

  .backbtn:hover {
    background: transparent !important;
    color: #181818 !important;
  }

  .backbtn:hover::before {
    background: url(../public/arrowbck.svg) no-repeat !important;
  }

  .slick-initialized .slick-slide>div {
    aspect-ratio: 245 / 280;
    min-height: 320px;
    max-width: 245px;
  }

  .premise {
    min-height: 320px;
  }

  .slick-track {
    display: flex;
  }

  .features_sec {
    padding-top: 0;
  }

  .features_sec .featurebox>div {
    width: 50%;
  }

  .features_sec .featurebox>div p {
    font-size: 16px;
  }

  .features_sec h3.title {
    font-size: 22px;
  }

  #root {
    padding-left: 0;
    padding-right: 0;
  }

  .homepage-ver-11 {
    padding-left: 16px;
    padding-right: 16px;
  }

  footer {
    flex-flow: column;
  }

  footer .privacybox {
    justify-content: flex-start;
    margin-top: 15px;
  }
}

.commonbgradius {
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border: solid 1px #dde4eb;
  background: #fff;
  border-radius: 0.35rem;
}

.pagetitle {
  font-size: 20px;
  color: var(--color-blue);
  font-family: 'Aileronbold';
}

.profileParent, .profileParent>div.tab-content .editteacherprofileparent {
  width: 100%;
  margin-top: 20px;
  padding: 20px;
  display: flex;
  flex-flow: row wrap;
}

.profileParent>div, .profileParent>div.tab-content .floating-label-input {
  width: calc(40% - 40px);
  margin-right: 40px;
}

.profileParent>button {
  width: 150px;
  margin-top: 30px;
}

.linebreak {
  width: 100% !important;
}
.changepwdbtn {
  margin-top: 30px;
  cursor: pointer;
  width: 200px;
  margin-right: 30px;
}
.profileParent>div.changepwdbtn {
  margin-top: 30px;
  cursor: pointer;
  width: 200px;
}


.modal-title.h4 {
  font-family: var(--font-aileron-bold);
  color: var(--color-blue);
}

.marginleftauto {
  margin-left: auto;
}

.maxwidth200 {
  max-width: 200px;
}

.profileParent>div.tableparent {
  width: 100%;
  margin-top: 40px;
}

.profileParent>div.tableparent .table-hover>tbody>tr:hover>* {
  background: var(--color-floralwhite-100) !important;
}
.profileParent>div.tableparent .table> thead th {
  font-family: var(--font-aileron-semibold);
  color: var(--color-blue);
}
.profileParent>div.tableparent .table> tbody td {
  font-family: var(--font-aileron-normal);
  color: var(--color-black);
  font-size: 16px;
}
.profileParent>div.tableparent a.page-link {
  background: var(--color-blue);
  color: #fff;
  outline: none !important;
  box-shadow: none;
}
.profileParent>div.tableparent .active>.page-link {
  background: var(--color-yellow);
}
button.contact:disabled {
  opacity: 0.5;
  pointer-events: none;
}
.profileParent>div.tab-content {
  width: 100%;
}

.alignhorizontalcenter {
  margin-left: auto;
  margin-right: auto;
}
.profileParent .card {
  border: none;
}
.profileParent ul {
  width: 100%;
}
.profileParent ul li button {
  font-family: var(--font-aileron-semibold);
  color: var(--color-blue);
}
.changepwdbox {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 400px;
}
.changepwdbox div button {
  width: 170px;
}
.profileParent>div.tableparent .table> tbody td:nth-child(2) {
  cursor: pointer;
  color: var(--color-blue);
}
.back {
  cursor: pointer;
}
.blackcolor {
  color: #000;
  font-family: var(--font-aileron-normal);
}