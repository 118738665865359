body {
  background-color: var(--color-floralwhite-100);
}

.group-icon,
.group-icon1 {
  height: 30px;
  width: 28.5px;
  position: relative;
}

.group-icon1 {
  height: 23.6px;
  width: 216.7px;
}

.group-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 12.600000000000364px;
}

.frame-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  padding: 0;
}

.about {
  width: 46px;
  min-width: 46px;
}

.about,
.sell-to-us,
.team,
.updates {
  position: relative;
  font-weight: 600;
  display: inline-block;
}

.sell-to-us {
  width: 66px;
  min-width: 66px;
  white-space: nowrap;
}

.team,
.updates {
  width: 41px;
  min-width: 41px;
}

.updates {
  width: 62px;
  min-width: 62px;
}

.about-parent,
.frame-container {
  display: flex;
  align-items: flex-start;
  max-width: 100%;
}

.about-parent {
  width: 420px;
  flex-direction: row;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.frame-container {
  width: 523.8px;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0;
  box-sizing: border-box;
}

.frame-child {
  height: 40px;
  width: 98px;
  position: relative;
  border-radius: var(--br-21xl);
  display: none;
}

.contact {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Aileronsemibold';
  color: #181818;
  font-size: 16px;
  width: 98px;
  height: 40px;
  border: 1px solid var(--color-blue);
  text-decoration: none;
  border-radius: 40px;
  background: linear-gradient(to right, var(--color-blue) 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-out;
  cursor: pointer;
}
.contact:hover, .contact.active {
  background-position: left bottom;
  color: #fff;
}

.frame-parent,
.vector-parent {
  display: flex;
  flex-direction: row;
}

.vector-parent {
  cursor: pointer;
  border: 0 solid var(--color-gray-100);
  padding: 0;
  background-color: var(--color-floralwhite-100);
  border-radius: var(--br-21xl);
  align-items: flex-start;
  justify-content: flex-start;
}



.frame-parent {
  align-self: stretch;
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-xl);
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-base);
  color: var(--color-gray-100);
  font-family: var(--font-aileron);
}

.building-on-legacy {
  margin: 0;
  align-self: stretch;
  position: relative;
  font-size: inherit;
  font-weight: 700;
  font-family: inherit;
  font-family: var(--font-aileron-bold);
  font-size: 32px;
  color: var(--color-homemenu);
}
.building-on-legacy span {
  color: var(--color-blue);
}

.were-an-investment {
  flex: 1;
  position: relative;
  line-height: 28px;
  display: inline-block;
  max-width: 100%;
  font-family: var(--font-aileron-normal);
  font-size: var(--padding-xl);
  color: var(--color-homemenu);
}

.were-an-investment-group-form-wrapper {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 69px;
  box-sizing: border-box;
  max-width: 100%;
  font-size: var(--font-size-xl);
}

.building-on-legacy-for-a-bette-parent {
  width: 914px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--gap-base);
  max-width: 100%;
  margin-top: 0;
}

.homepage-ver-11-inner {
  align-self: stretch;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
  padding: 0 0 40px;
  box-sizing: border-box;
  max-width: 100%;
  text-align: center;
  font-size: var(--font-size-29xl);
  color: var(--color-gray-100);
  font-family: var(--font-aileron);
}

.premise1 {
  position: relative;
  display: inline-block;
  min-width: 64px;
  font-family: var(--font-aileron-bold);
  font-size: 14px;
  color: var(--color-homemenu);
  margin-bottom: 22px;
}

.premise-wrapper {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0 var(--padding-9xs);
}

.premise-child {
  align-self: stretch;
  height: 460px;
  position: relative;
  max-width: 100%;
  overflow: hidden;
  flex-shrink: 0;
  object-fit: cover;
}

.premise {
  height: auto;
  width: calc(25% - 20px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 0;
  max-width: 100%;
  aspect-ratio: 280 / 325;
  opacity: 1;
  transform: translateY(20px);
  
  cursor: pointer;
}

.meet-our-team,
.what-we-are {
  position: relative;
}

.meet-our-team {
  display: inline-block;
  min-width: 114px;
}

.what-we-are-looking-for-parent {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 227px;
  max-width: 100%;
}

.notice-board {
  position: relative;
  display: inline-block;
  min-width: 104px;
}

.frame-div {
  width: 940px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  max-width: 100%;
  gap: var(--gap-xl);
}

.frame-inner,
.frame-item {
  position: relative;
  max-width: 100%;
  overflow: hidden;
  max-height: 100%;
  object-fit: cover;
  min-height: 460px;
}

.rectangle-div {
  width: 398px;
  height: 460px;
  position: relative;
  border-radius: var(--br-5xs);
  background-color: var(--color-gray-100);
  display: none;
  max-width: 100%;
}

.spring-street-capital {
  margin: 0;
  width: 100%;
  height: auto;
  position: relative;
  font-size: inherit;
  line-height: 34px;
  font-weight: 600;
  font-family: inherit;
  white-space: normal;
  display: inline-block;
  flex-shrink: 0;
  z-index: 1;
  font-size: 24px;
  font-family: var(--font-aileron-semibold);
  color: var(--color-white);
}

.rectangle-icon {
  height: 32px;
  width: 136px;
  position: relative;
  border-radius: var(--br-21xl);
  display: none;
}

.more-updates {
  position: relative;
  font-size: var(--font-size-xs);
  letter-spacing: 1px;
  text-transform: uppercase;
  font-weight: 600;
  font-family: var(--font-aileron-semibold);
  color: var(--color-white);
  text-align: center;
  display: inline-block;
  min-width: 101px;
  white-space: nowrap;
  z-index: 1;
  font-size: 12px;
}

.frame-parent1,
.rectangle-parent,
.vector-group {
  align-items: flex-start;
  justify-content: flex-start;
}

.vector-group {
  border: 1px solid #FFFBF1;
  border-radius: 3px;
  width: 136px;
  height: 32px;
  display: block;
  border-radius: 40px;

  background: linear-gradient(to right, white 50%, transparent 50%);
  background-size: 200% 100%;
  background-position: right bottom;
  transition: all .5s ease-out;
}


.frame-parent1,
.rectangle-parent {
  max-width: 100%;
}

.rectangle-parent {
  border-radius: 4px;
  background-color: #264d9b;
  display: flex;
  flex-direction: column;
  padding: var(--padding-5xl);
  box-sizing: border-box;
  gap: 0;
  width: 100%;
  height: 100%;
  justify-content: space-between;
}

.frame-parent1 {
  align-self: stretch;
  display: grid;
  flex-direction: row;
  gap: var(--gap-5xl);
  grid-template-columns: repeat(3, minmax(298px, 1fr));
  font-size: var(--font-size-5xl);
  color: var(--color-white);
}

.frame-group,
.premise-parent {
  flex: 1;
  display: flex;
  max-width: 100%;
}

.frame-group {
  flex-direction: column;
  justify-content: flex-start;
  gap: var(--gap-3xl);
  min-width: 802px;
}

.premise-parent {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0;
  justify-content: space-between;
}

.homepage-ver-11,
.homepage-ver-11-child {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-sizing: border-box;
}

.homepage-ver-11-child {
  align-self: stretch;
  flex-direction: row;
  padding: 0 var(--padding-7xs);
  max-width: 100%;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-gray-100);
  font-family: var(--font-aileron);
}

.homepage-ver-11 {
  width: 100%;
  position: relative;
  background-color: var(--color-floralwhite-100);
  overflow: hidden;
  flex-direction: column;
  padding: var(--padding-base) var(--padding-17xl) 0;
  gap: var(--gap-45xl);
  letter-spacing: normal;
}

.about-parent a {
  font-family: 'Aileronsemibold';
  color: #181818;
  font-size: 16px;
  line-height: 19.2px;
  text-decoration: none;
  padding: 10px 21px
}

.premise .imgparent {
  transform: translateY(0);
  /* Initially, stay at the same position */
  transition: all 0.5s ease 0s;
  min-height: calc(100% - 40px);
  border-radius: 4px;
  border: 1px solid var(--color-blue);
  overflow: hidden;
}

.premise img {
  width: 100%;
  transition: all 0.5s ease 0s;
  height: 100%;
  position: relative;
  top: 0;
  transition: all 0.5s ease 0s;
  border-radius: 4px;
}
.premise video {
  border-radius: 4px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.premise:hover {
  /* overflow: hidden; */
}
.premise .rectangle-parent {
  top: 0;
  position: relative;
  transition: all 0.5s ease 0s;
  
}
.premise:hover .imgparent img, .premise:hover .rectangle-parent {
  top: -15px;
  
  position: relative;
  transition: all 0.5s ease 0s;
  border-radius: 4px;
  /* Slide up on hover */

}

.premise:hover img {
  transform: 1;
  /* Scale down on hover */
  transition: transform 0.3s ease-in-out
}
.premise:hover .vector-group {
  background-position: left bottom;
}
.vector-group a {
  text-decoration: none;
}
.premise:hover .vector-group a {
  color: var(--color-homemenu);
}
.animate {
  animation: fadeInRight .5s ease forwards;
}
@keyframes fadeInRight {
  from {
    /* opacity: .4; */
    transform: translateY(20px);
  }
  to {
    /* opacity: 1; */
    transform: translateY(0);
  }
}

/* .homepage-ver-11.fixedheader header {
  position: fixed;
  width: 100%;
  left: 0;
  padding: 16px 36px;
  box-sizing: border-box;
  z-index: 9;
  background: var(--color-floralwhite-100);
  top: 0;
}
.homepage-ver-11 header  {
  transition: all .3s ease-in-out;
} */