.container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 400px;
    margin: auto;
  }
  
  .floating-label-input {
    position: relative;
    margin-bottom: 20px;
  }
  
  .floating-label-input input {
    width: 100%;
    padding: 10px 10px 10px 15px;
    display: block;
    border: none;
    border: 1px solid #757575;
    background-color: transparent;
    color: #000;
    font-size: 16px;
    border-radius: 10px;
  }
  
  .floating-label-input input:focus {
    outline: none;
  }
  
  .floating-label-input label {
    color: #999;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 10px;
    top: 10px;
    transition: 0.2s ease all;
    background: #fff;
    padding-left: 5px;
    padding-right: 5px;
  }
  
  .floating-label-input input:focus ~ label,
  .floating-label-input input:not(:placeholder-shown) ~ label {
    top: -10px;
    font-size: 12px;
    color: #5264AE;
  }
  
  .error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  